<template>
    <div class="daily-sales-report-box">
        <div class="daily-sales-report">
            <!-- 导出excel -->
            <exportDom :dom-id="'#dayReport'" :excel-name="'销售日报'"></exportDom>
            <div class="table-title-box">
                <el-date-picker
                    v-model="timeData"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期"
                    @change="changeTimeFun"
                    prefix-icon="1"
                    clear-icon="1"
                >
                </el-date-picker>
                <i class="el-icon-arrow-down icon-hahahahahahah"></i>
                <el-select @change="changeTimeFun" v-model="selectValue" placeholder="请选择">
                    <el-option
                        v-for="item in options"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                销售日报表
            </div>
            <div class="table-number-list-box">
                <table class="table-number-list" id="dayReport">
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td colspan="7">
                            {{ item.name }}
                        </td>
                        <td>{{ item.fhfl }}</td>
                        <td>
                            {{ item.qsfl }}
                        </td>
                        <td>{{ item.tzhqsfl }}</td>
                        <td></td>
                        <td></td>
                        <td>{{ item.tje }}</td>
                        <td>{{ item.bsfl }}</td>
                        <td>{{ item.tzhbsfl }}</td>
                        <td></td>
                        <td>{{ item.bsje }}</td>
                        <td>{{ item.sjje }}</td>
                        <td>{{ item.hjje }}</td>
                        <td colspan="7"></td>
                    </tr>
                    <tr>
                        <th style="min-width:.7rem">
                            序号
                        </th>
                        <th style="min-width: 2rem">
                            任务单号
                        </th>
                        <th style="min-width: 3rem">
                            客户名称
                        </th>
                        <th style="min-width: 3rem">
                            工程名称
                        </th>
                        <th>场站</th>
                        <th style="min-width: 2rem">
                            施工部位
                        </th>
                        <th>产品标号</th>
                        <th>发货方量</th>
                        <th>签收方量</th>
                        <th>调整后签收方量</th>
                        <th>砼单价</th>
                        <th>调整后砼单价</th>
                        <th>累积砼金额</th>
                        <th>泵送方量</th>
                        <th>调整后泵送方量</th>
                        <th>泵送单价</th>
                        <th>累积泵送金额</th>
                        <th>砂浆费用</th>
                        <th>金额合计</th>
                        <th>补方</th>
                        <th>补方金额</th>
                        <th>运输里程</th>
                        <th>泵送/自卸</th>
                        <th>小计金额</th>
                        <th>业务员</th>
                        <th class="mark">
                            备注
                        </th>
                        <th>发货单</th>
                        <th>状态</th>
                    </tr>
                    <template v-if="tableDataList.length > 0">
                        <tr v-for="(item, index) in tableDataList" :key="index">
                            <td>{{ index+1 }}</td>
                            <td>{{ item.rwdh }}</td>
                            <td :title="item.khmc">
                                {{ item.khmc }}
                            </td>
                            <td :title="item.gcmc">
                                {{ item.gcmc }}
                            </td>
                            <td>{{ item.station_name }}</td>
                            <td :title="item.sgbw">
                                {{ item.sgbw }}
                            </td>
                            <td>{{ item.cpbh }}</td>
                            <td>{{ item.fhfl }}</td>
                            <td>{{ item.qsfl }}</td>
                            <td>{{ item.tzhqsfl }}</td>
                            <td>{{ item.tdj }}</td>
                            <td>{{ item.tzhtdj }}</td>
                            <td>{{ item.tje }}</td>
                            <td>{{ item.bsfl }}</td>
                            <td>{{ item.tzhbsfl }}</td>
                            <td></td>
                            <!-- 泵送单价 -->
                            <td>{{ item.bsje }}</td>
                            <td>{{ item.sjje }}</td>
                            <td>{{ item.hjje }}</td>
                            <td>{{ item.bfs }}</td>

                            <!-- 补方 -->
                            <td>{{ item.bfje }}</td>
                            <!-- 补方金额 -->
                            <td>{{ item.yslc }}</td>
                            <td>{{ item.jzfs }}</td>
                            <td>{{ item.xjje }}</td>
                            <td>{{ item.ywy }}</td>
                            <td class="mark" :title="item.bz">
                                {{ item.bz }}
                            </td>
                            <td>{{ item.fhd }}</td>
                            <td>{{ item.zt }}</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="50" class="noDataTr">
                                暂无数据
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
            <div class="table-bottom-btn">
                <button @click="showDayTrend = true">
                    日变化趋势图
                </button>
                <button @click="showMonthTrend = true">
                    月变化趋势图
                </button>
            </div>
            <dayTrend v-if="showDayTrend" :time-data="timeData" @closeTrend="closeTrend"></dayTrend>
            <monthTrend v-if="showMonthTrend" :time-data="timeData" @closeTrend="closeTrend"></monthTrend>
        </div>
    </div>
</template>

<script>
import exportDom from './components/export';
import dayTrend from './components/dailySalesReport/dayTrend';
import monthTrend from './components/dailySalesReport/monthTrend';
export default {
    data() {
        return {
            showDayTrend: false, // 日变化趋势
            showMonthTrend: false, // 月变化趋势
            tableData: [],
            tableDataList: [],
            timeData: '',

            options: [],
            selectValue: '',
        };
    },
    components: {
        dayTrend,
        monthTrend,
        exportDom,
    },
    mounted() {
        this.$nextTick(async () => {
            this.timeData = this.switchTime();
            await this.salesanalysisStation();
        });
    },
    methods: {
        closeTrend() {
            this.showDayTrend = false;
            this.showMonthTrend = false;
        },
        changeTimeFun() {
            this.salesanalysisDay();
        },
        switchTime() {
            const date = new Date(new Date().getTime() - 3600 * 1000 * 24);
            const y = date.getFullYear(); // 获取完整的年份(4位,1970)
            let m = date.getMonth() + 1; // 获取月份(0-11,0代表1月,用的时候记得加上1)
            let d = date.getDate(); // 获取日(1-31)
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            return y + '-' + m + '-' + d;
        },
        salesanalysisStation() {
            this.$axios
                .get('/interfaceApi/report/salesanalysis/station')
                .then(res => {
                    this.options = res;
                    const obj = {
                        id: ' ',
                        name: '全部',
                    };
                    this.options.unshift(obj);
                    this.selectValue = this.options[0].id;
                    this.salesanalysisDay();
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
        salesanalysisDay() {
            this.$axios
                .get(`/interfaceApi/report/salesanalysis/xsqk/day?date=${this.timeData}&station_id=${this.selectValue}`)
                // .get(`/interfaceApi/report/salesanalysis/xsqk/day?date=2020-06-14&station_id=${this.selectValue}`)
                .then(res => {
                    this.tableDataList = res.list;
                    this.tableData = res.total;
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.daily-sales-report-box
    width 100%
    background #ffffff
    .export
        margin-bottom .1rem
        height .35rem
        .export_btn
            float right
            border: none;
            height: 0.36rem;
            font-size: 0.14rem;
            min-width: 0.36rem;
            line-height: 0.36rem;
            cursor: pointer;
            padding: 0 0.2rem;
            border-radius: 2px;
            background: #f1f4f7;
            color: #2978ff;
            text-align: center;
    .daily-sales-report
        position relative
        height 100%
        top 0
        left 0
        bottom 0
        right 0
        background #ffffff
        padding .2rem
    .table-title-box
        width 100%
        background:#EDF0F5;
        height .6rem
        display flex
        justify-content center
        align-items center
        border 1px solid #D7D7D7
        border-bottom none
        color #022782
        font-size .18rem
        font-weight 700
        .el-input
            width .95rem !important
            cursor pointer
            input
                border none
                background #EDF0F5
                font-size .18rem
                color #022782
                font-weight 700
                padding 0
                width .95rem
                cursor pointer
        .icon-hahahahahahah
            margin-right .2rem
        .el-input__icon
            color #022782
            margin-left .02rem
    .table-number-list-box
        width 100%
        overflow auto
        height calc(100% - 1.6rem)
        .table-number-list
            border-collapse collapse
            table-layout fixed
            overflow auto
            border 1px solid #D7D7D7
            position relative
            border-top none
            tr, td, th
                border 1px solid #D7D7D7
                text-align center
                font-size .16rem
                height .4rem
                color #333333
                padding .1rem
            th
                min-width 1.1rem
                max-width 2.1rem
                background #EDF0F5
                font-size .18rem
                color #022782
                width 2rem
            td
                min-width 1.1rem
                max-width 2.1rem
                white-space nowrap
                word-wrap normal
                overflow hidden
                text-overflow ellipsis
            .noDataTr
                width 100%
                text-align center
                color #ccc
            // .position-table
            //     position relative
            //     top 0
    .table-bottom-btn
        display flex
        button
            width 1.3rem
            height .4rem
            margin: .2rem .2rem 0 0
            border-radius: .04rem;
            border none
            color #fff
            font-size .18rem
            &:first-child
                background:linear-gradient(90deg,rgba(58,150,254,1),rgba(64,182,255,1));
            &:last-child
                background:linear-gradient(90deg,rgba(254,136,58,1),rgba(255,194,64,1));

</style>
